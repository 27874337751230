import Observation from 'entities/observations/Observation';
import * as observationsGateway from 'gateways/observationsGateway';
import { toastr } from 'react-redux-toastr';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as observationsActions from './observations.actions';
import actionTypes from './observations.actionTypes';
import { format } from 'date-fns';

export function* fetchPlayerObservationsSaga({
    playerId,
    readOnly = false,
    observationType,
}: ReturnType<typeof observationsActions.observationsListRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchPlayerObservations,
            {
                playerId,
                readOnly,
                observationType,
            },
        );

        if (observationsList.length > 0) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchPlayerObservationsByPeriodSaga({
    playerId,
    startDate,
    endDate,
}: ReturnType<typeof observationsActions.periodObservationsListRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchPlayerObservationsByPeriod,
            { playerId, startDate, endDate },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchTeamObservationsByPeriodSaga({
    organizationId,
    teamId,
    from,
    to,
    onSuccess,
    onFail,
}: ReturnType<typeof observationsActions.periodTeamObservationsListRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchTeamObservationsByPeriod,
            { teamId, from, to, organizationId },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(`Failed to fetch observations for Team: ${teamId}`, e);
        toastr.error('Failed to fetch team observations', '');

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchEventTeamObservationsSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof observationsActions.eventTeamObservationsListRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchTeamObservationsForEvent,
            { teamId, eventId, organizationId },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Team: ${teamId}`,
            },
            e,
        );
    }
}

export function* fetchPlayerEventObservationsSaga({
    playerId,
    eventId,
}: ReturnType<typeof observationsActions.playerEventObservationsRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchPlayerEventObservations,
            {
                playerId,
                eventId,
            },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchPlayerGoalObservationsSaga({
    playerId,
    goalId,
}: ReturnType<typeof observationsActions.playerGoalObservationsRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchPlayerGoalObservations,
            {
                playerId,
                goalId,
            },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchPlayerInjuryObservationsSaga({
    playerId,
    injuryId,
}: ReturnType<typeof observationsActions.playerInjuryObservationsRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchPlayerInjuryObservations,
            {
                playerId,
                injuryId,
            },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observations for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchUserObservationsByPeriodSaga({
    organizationId,
    teamId,
    userId,
    toDate,
    fromDate,
    onSuccess,
    onFail,
}: ReturnType<typeof observationsActions.userObservationsForPeriodListRequest>) {
    try {
        const observationsList: Observation[] = yield call(
            observationsGateway.fetchUserObservationsByPeriod,
            { teamId, fromDate, toDate, organizationId, userId },
        );

        if (observationsList) {
            yield put(observationsActions.observationsListRecieved({ observationsList }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        const formattedFromDate = format(fromDate, 'dd MMM');
        const formattedtoDate = format(toDate, 'dd MMM');
        const errorMessage = `Failed to fetch observations for: ${formattedFromDate} - ${formattedtoDate}`;
        console.error(errorMessage, e);
        toastr.error(
            'Failed to fetch team observations',
            `${errorMessage} | ${teamId} | ${organizationId} | ${userId}`,
        );

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchObservationSaga({
    observationId,
    playerId,
}: ReturnType<typeof observationsActions.observationsDataRequest>) {
    try {
        const observationData: Observation = yield call(observationsGateway.fetchObservation, {
            observationId,
            playerId,
        });

        if (observationData) {
            yield put(observationsActions.observationDataRecieved({ observationData }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch observation: ${observationId}`,
            },
            e,
        );
    }
}

export function* deleteObservationSaga({
    observationId,
    playerId,
    onSuccess,
}: ReturnType<typeof observationsActions.deleteObservation>) {
    try {
        yield call(observationsGateway.removeObservation, {
            observationId,
            playerId,
        });
        yield put(
            observationsActions.removeObservationFromStore({
                observationId,
            }),
        );
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        yield call(toastr.error, 'Failed to delete observation', '');
        console.error(`Failed to remove observation: ${observationId} (entities)`, e);
    }
}

export function* createObservationSaga({
    playerId,
    observationData,
    onSuccess,
}: ReturnType<typeof observationsActions.createObservation>) {
    try {
        const observationId: string = yield call(observationsGateway.createObservation, {
            playerId,
            observationData,
        });
        yield put(observationsActions.observationsDataRequest({ observationId, playerId }));

        if (onSuccess) {
            yield call(onSuccess, observationId);
        }

        return observationId;
    } catch (e) {
        yield call(toastr.error, 'Failed to create observation', '');
        console.error('Failed to create observation', e);
        return null;
    }
}

export function* updateObservationSaga({
    playerId,
    observationId,
    observationData,
    onSuccess,
}: ReturnType<typeof observationsActions.updateObservation>) {
    try {
        yield call([observationsGateway, observationsGateway.updateObservation], {
            observationId,
            playerId,
            observationData,
        });
        yield put(observationsActions.observationsDataRequest({ observationId, playerId }));
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(`Failed to update observation ${observationId} for Player: ${playerId}`, e);
        yield call(toastr.error, 'Failed to update observation', '');
    }
}

export default function* observationsSagas() {
    yield all([
        takeEvery(actionTypes.OBSERVATIONS_LIST_REQUEST, fetchPlayerObservationsSaga),
        takeEvery(
            actionTypes.PERIOD_PLAYER_OBSERVATIONS_LIST_REQUEST,
            fetchPlayerObservationsByPeriodSaga,
        ),
        takeEvery(
            actionTypes.PERIOD_TEAM_OBSERVATIONS_LIST_REQUEST,
            fetchTeamObservationsByPeriodSaga,
        ),
        takeEvery(
            actionTypes.USER_OBSERVATIONS_FOR_PERIOD_LIST_REQUEST,
            fetchUserObservationsByPeriodSaga,
        ),
        takeEvery(actionTypes.EVENT_TEAM_OBSERVATIONS_LIST_REQUEST, fetchEventTeamObservationsSaga),
        takeEvery(
            actionTypes.PLAYER_EVENT_OBSERVATIONS_LIST_REQUEST,
            fetchPlayerEventObservationsSaga,
        ),
        takeEvery(
            actionTypes.PLAYER_GOAL_OBSERVATIONS_LIST_REQUEST,
            fetchPlayerGoalObservationsSaga,
        ),
        takeEvery(
            actionTypes.PLAYER_INJURY_OBSERVATIONS_LIST_REQUEST,
            fetchPlayerInjuryObservationsSaga,
        ),
        takeEvery(actionTypes.OBSERVATION_DATA_REQUEST, fetchObservationSaga),
        takeLatest(actionTypes.DELETE_OBSERVATION, deleteObservationSaga),
        takeLatest(actionTypes.UPDATE_OBSERVATION, updateObservationSaga),
        takeLatest(actionTypes.CREATE_OBSERVATION, createObservationSaga),
    ]);
}
