import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import { TeamUpdate } from './TeamUpdates';
import actionTypes from './teams.actionTypes';
import Team, { GameTypesMetadata } from './Team';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';

const initialState = {
    teamsHash: {},
    teamsUpdatesHash: {},
    teamsPlayersLabelsHash: {},
    teamsGameTypesHash: {},
};

type State = {
    teamsHash: Record<string, Team>;
    teamsUpdatesHash: Record<string, TeamUpdate>;
    teamsPlayersLabelsHash: Record<string, PlayersLabelsMetadata>;
    teamsGameTypesHash: Record<string, GameTypesMetadata>;
};

export default (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TEAM_DATA_RECEIVED:
            return {
                ...state,
                teamsHash: {
                    ...state.teamsHash,
                    [action.teamData.id]: {
                        ..._get(state, `teamsHash.${action.teamData.id}`, {}),
                        ...action.teamData,
                    },
                },
            };
        case actionTypes.TEAMS_LIST_RECIEVED:
            return {
                ...state,
                teamsHash: {
                    ...state.teamsHash,
                    ..._keyBy(action.teamsList, 'id'),
                },
            };
        case actionTypes.REMOVE_TEAM:
            return {
                ...state,
                teamsHash: {
                    ..._omit(state.teamsHash, action.teamId),
                },
            };
        case actionTypes.TEAM_UPDATES_LIST_RECEIVED:
            return {
                ...state,
                teamsUpdatesHash: {
                    ...state.teamsUpdatesHash,
                    ..._keyBy(action.teamUpdatesList, 'id'),
                },
            };
        case actionTypes.PLAYERS_LABELS_DATA_RECEIVED:
            return {
                ...state,
                teamsPlayersLabelsHash: {
                    ...state.teamsPlayersLabelsHash,
                    [action.teamId]: action.playersLabels,
                },
            };
        case actionTypes.GAME_TYPES_DATA_RECEIVED:
            return {
                ...state,
                teamsGameTypesHash: {
                    ...state.teamsGameTypesHash,
                    [action.teamId]: action.gameTypesMetadata,
                },
            };
        default:
            return state;
    }
};
