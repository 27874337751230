import { TeamUpdate } from './TeamUpdates';
import actionTypes from './teams.actionTypes';
import Team, { GameTypesMetadata, TeamData } from './Team';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';

export const teamDataRecieved = ({ teamData }: { teamData: Team }) => ({
    type: actionTypes.TEAM_DATA_RECEIVED,
    teamData,
});

export const teamsListRequest = ({ organizationId }: { organizationId: string }) => ({
    type: actionTypes.TEAMS_LIST_REQUEST,
    organizationId,
});

export const teamsListRecieved = ({ teamsList }: { teamsList: Team[] }) => ({
    type: actionTypes.TEAMS_LIST_RECIEVED,
    teamsList,
});

export const deleteTeamRequest = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.DELETE_TEAM,
    teamId,
    organizationId,
});

export const teamDataUpdate = ({
    teamId,
    organizationId,
    teamData,
}: {
    teamId: string;
    organizationId: string;
    teamData: TeamData;
}) => ({
    type: actionTypes.TEAM_DATA_UPDATE,
    teamData,
    teamId,
    organizationId,
});

export const teamDataRequest = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.TEAM_DATA_REQUEST,
    teamId,
    organizationId,
});

export const removeTeam = ({ teamId }: { teamId: string }) => ({
    type: actionTypes.REMOVE_TEAM,
    teamId,
});

export const requestTeamUpdatesList = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => ({
    type: actionTypes.TEAM_UPDATES_LIST_REQUEST,
    organizationId,
    teamId,
});

export const teamUpdatesListReceived = ({
    teamUpdatesList,
}: {
    teamUpdatesList: TeamUpdate[];
}) => ({
    type: actionTypes.TEAM_UPDATES_LIST_RECEIVED,
    teamUpdatesList,
});

export const playersLabelsDataReceived = ({
    playersLabels,
    teamId,
}: {
    playersLabels: PlayersLabelsMetadata;
    teamId: string;
}) => ({
    type: actionTypes.PLAYERS_LABELS_DATA_RECEIVED,
    playersLabels,
    teamId,
});

export const requestPlayersLabels = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.PLAYERS_LABELS_DATA_REQUEST,
    teamId,
    organizationId,
});

export const updatePlayersLabels = ({
    playersLabelsMetadata,
    teamId,
    organizationId,
}: {
    playersLabelsMetadata: PlayersLabelsMetadata;
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.UPDATE_PLAYERS_LABELS,
    playersLabelsMetadata,
    teamId,
    organizationId,
});

export const gameTypesDataReceived = ({
    gameTypesMetadata,
    teamId,
}: {
    gameTypesMetadata: GameTypesMetadata;
    teamId: string;
}) => ({
    type: actionTypes.GAME_TYPES_DATA_RECEIVED,
    gameTypesMetadata,
    teamId,
});

export const requestGameTypes = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.GAME_TYPES_DATA_REQUEST,
    teamId,
    organizationId,
});

export const updateGameTypes = ({
    gameTypesMetadata,
    teamId,
    organizationId,
}: {
    gameTypesMetadata: GameTypesMetadata;
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.UPDATE_GAME_TYPES,
    gameTypesMetadata,
    teamId,
    organizationId,
});
