enum ScheduleViewTypes {
    planning = 'planning',
    calendar = 'calendar',
    simpleCalendar = 'simple-calendar',
}

enum PlanningPeriods {
    week = 'week',
    month = 'month',
    day = 'day',
}

enum CalendarPeriods {
    week = 'week',
    month = 'month',
    year = 'year',
}

export { ScheduleViewTypes, PlanningPeriods, CalendarPeriods };
