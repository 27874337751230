import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Permissions } from 'entities/access/Permissions';
import { Box, useMediaQuery, useTheme } from 'common/components';
import AssistantButton from 'features/assistant/AssistantButton';
import TeamStatsBtn from 'features/team/team-stats/TeamStatsBtn';
import { useTeamPermissions } from 'entities/access/access.utils';
import { organizationsListSelector } from 'entities/organizations/organizations.selectors';
import {
    AnalyticsIcon,
    CalendarIcon,
    ConditionsIcon,
    DashboardIcon,
    FitnessDataIcon,
    MedicalIcon,
    PeopleIcon,
    StarsIcon,
    TrendingUpIcon,
} from 'common/icons';
import TeamSectionWidget from './TeamSectionWidget';
import {
    checkIfCalendarActive,
    checkIfConditionsActive,
    checkIfDashboardActive,
    checkIfEvaluationActive,
    checkIfFitnessDataActive,
    checkIfMedicalActive,
    checkIfPlayersActive,
    checkIfReportsActive,
    checkIfStatisticsActive,
    getCalendarUrl,
} from './sidebar.utils';

type TeamNavProps = {
    isExpanded?: boolean;
    className?: string;
    navItemClassName?: string;
    isTopNav?: boolean;
    onClose?: VoidFunction;
};

const TeamNav: React.FC<TeamNavProps> = ({
    isExpanded = false,
    className,
    navItemClassName,
    isTopNav,
    onClose = () => {},
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const organizationId = pathname.split('/')[2];
    const teamId = pathname.split('/')[4];
    const hasTeamPermission = useTeamPermissions({ organizationId, teamId });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const organizationsList = useSelector(organizationsListSelector);
    const organizationData = useMemo(() => {
        return organizationsList.find((organization) => organization.id === organizationId);
    }, [organizationId, organizationsList]);
    const hasSelectedTeam = organizationId && teamId && organizationData?.features?.core;
    const isScouting = pathname.split('/')[3] === 'scouting';

    if (!hasSelectedTeam || isScouting) {
        return null;
    }

    const isFitnessBtnEnabled =
        hasTeamPermission(Permissions.canWriteFitnessData) &&
        ['Z7cAvoXQXwyChFIO1Rus', '9RRNAXYDt0Dcho0qkdFJ', 'YKRTPw2rfRe8YNXdXrbn'].includes(
            organizationId,
        );

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} className={className}>
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.calendar')}
                hidden={!hasTeamPermission(Permissions.canReadTeamCalendar)}
                IconComponent={CalendarIcon}
                path={getCalendarUrl(pathname, organizationId, teamId)}
                isActive={checkIfCalendarActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.dashboard')}
                hidden={!hasTeamPermission(Permissions.canReadTeamDashboard)}
                IconComponent={DashboardIcon}
                path={`/organizations/${organizationId}/teams/${teamId}`}
                isActive={checkIfDashboardActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.players')}
                hidden={!hasTeamPermission(Permissions.canReadTeamPlayersPage)}
                IconComponent={PeopleIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/players`}
                isActive={checkIfPlayersActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.medical')}
                hidden={!hasTeamPermission(Permissions.canReadTeamDoctorPage)}
                IconComponent={MedicalIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/medical`}
                isActive={checkIfMedicalActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.evaluation')}
                hidden={
                    !hasTeamPermission(Permissions.canReadTeamEvaluationsPage) || Boolean(isTopNav)
                }
                IconComponent={StarsIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/evaluations/session`}
                isActive={checkIfEvaluationActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.statistics')}
                hidden={!hasTeamPermission(Permissions.canReadTeamAttendancePage)}
                IconComponent={TrendingUpIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/statistics`}
                isActive={checkIfStatisticsActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.conditions')}
                hidden={!hasTeamPermission(Permissions.canReadTeamConditionsPage)}
                IconComponent={ConditionsIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/conditions/day`}
                isActive={checkIfConditionsActive(pathname)}
            />
            <TeamSectionWidget
                isExpanded={isExpanded}
                className={navItemClassName}
                onClose={onClose}
                title={t('sidebar.fitnessData')}
                hidden={!isFitnessBtnEnabled}
                IconComponent={FitnessDataIcon}
                path={`/organizations/${organizationId}/teams/${teamId}/fitness-data`}
                isActive={checkIfFitnessDataActive(pathname)}
            />
            {!isMobile && (
                <TeamSectionWidget
                    isExpanded={isExpanded}
                    className={navItemClassName}
                    onClose={onClose}
                    title={t('sidebar.reports')}
                    hidden={
                        !hasTeamPermission(Permissions.canReadTeamAIReports) ||
                        !organizationData?.features?.analytics
                    }
                    IconComponent={AnalyticsIcon}
                    path={`/organizations/${organizationId}/teams/${teamId}/reports`}
                    isActive={checkIfReportsActive(pathname)}
                />
            )}
            {hasTeamPermission(Permissions.canReadTeamAssistant) && (
                <AssistantButton
                    teamId={teamId}
                    className={navItemClassName}
                    organizationId={organizationId}
                    isExpanded={isExpanded}
                    title={t('sidebar.assistant')}
                    hidden={!hasTeamPermission(Permissions.canReadTeamAssistant)}
                />
            )}
            {hasTeamPermission(Permissions.canReadTeamStats) && (
                <TeamStatsBtn
                    className={navItemClassName}
                    teamId={teamId}
                    organizationId={organizationId}
                    isExpanded={isExpanded}
                    hidden={!hasTeamPermission(Permissions.canReadTeamStats)}
                />
            )}
        </Box>
    );
};

export default TeamNav;
