import { db, functions } from 'common/firebase';
import Observation, { ObservationData, ObservationType } from 'entities/observations/Observation';
import { mapObservationFromDatesStrings } from 'entities/observations/observations.utils';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const fetchPlayerObservations = ({
    playerId,
    readOnly,
    observationType,
}: {
    playerId: string;
    readOnly: boolean;
    observationType?: ObservationType;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);
    const constraints = [];

    if (observationType) {
        constraints.push(where('type', '==', observationType));
    }

    if (readOnly) {
        constraints.push(where('accessType', '==', 'all'));
    }

    const q = query(observationsRef, ...constraints);
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((observations) => observations.map((obj: Observation) => ({ ...obj, playerId })));
};

export const fetchPlayerObservationsByPeriod = ({
    playerId,
    startDate,
    endDate,
}: {
    playerId: string;
    startDate?: Date;
    endDate?: Date;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);
    const restrictions = [];

    if (startDate) {
        restrictions.push(where('createDate', '>=', startDate));
    }

    if (endDate) {
        restrictions.push(where('createDate', '<=', endDate));
    }

    const q = query(observationsRef, ...restrictions);

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((observations) => observations.map((obj: Observation) => ({ ...obj, playerId })));
};

export const fetchPlayerEventObservations = ({
    playerId,
    eventId,
}: {
    playerId: string;
    eventId: string;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);
    const q = query(observationsRef, where('target.eventId', '==', eventId));

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((observations) => observations.map((obj: Observation) => ({ ...obj, playerId })));
};

export const fetchPlayerGoalObservations = ({
    playerId,
    goalId,
}: {
    playerId: string;
    goalId: string;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);
    const q = query(observationsRef, where('target.goalId', '==', goalId));

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((observations) => observations.map((obj: Observation) => ({ ...obj, playerId })));
};

export const fetchPlayerInjuryObservations = ({
    playerId,
    injuryId,
}: {
    playerId: string;
    injuryId: string;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);
    const q = query(observationsRef, where('target.injuryId', '==', injuryId));

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((observations) => observations.map((obj: Observation) => ({ ...obj, playerId })));
};

export const fetchObservation = ({
    observationId,
    playerId,
}: {
    playerId: string;
    observationId: string;
}) => {
    const observationRef = doc(db, `players/${playerId}/observations/${observationId}`);

    return getDoc(observationRef)
        .then(handleDocumentSnapshot)
        .then((obj) => ({ ...obj, playerId }));
};

export const createObservation = ({
    playerId,
    observationData,
}: {
    playerId: string;
    observationData: ObservationData;
}) => {
    const observationsRef = collection(db, `players/${playerId}/observations`);

    return addDoc(observationsRef, observationData).then((doc) => doc.id);
};

export const updateObservation = ({
    observationId,
    playerId,
    observationData,
}: {
    playerId: string;
    observationId: string;
    observationData: ObservationData;
}) => {
    const observationRef = doc(db, `players/${playerId}/observations/${observationId}`);

    return updateDoc(observationRef, observationData);
};

export const removeObservation = ({
    observationId,
    playerId,
}: {
    playerId: string;
    observationId: string;
}) => {
    const observationRef = doc(db, `players/${playerId}/observations/${observationId}`);

    return deleteDoc(observationRef);
};

const fetchTeamObservationsByPeriodFunc = httpsCallable(
    functions,
    'getTeamObservationsForPeriodV2',
);
export const fetchTeamObservationsByPeriod = ({
    teamId,
    organizationId,
    from,
    to,
}: {
    teamId: string;
    organizationId: string;
    from: Date;
    to: Date;
}) => {
    return fetchTeamObservationsByPeriodFunc({
        teamId,
        organizationId,
        from: from.toISOString(),
        to: to.toISOString(),
    }).then((res) => (res.data as any[]).map(mapObservationFromDatesStrings));
};

const fetchTeamObservationsFotEventFunc = httpsCallable(functions, 'getTeamObservationsForEventV2');
export const fetchTeamObservationsForEvent = ({
    teamId,
    organizationId,
    eventId,
}: {
    teamId: string;
    organizationId: string;
    eventId: string;
}) => {
    return fetchTeamObservationsFotEventFunc({
        teamId,
        organizationId,
        eventId,
    }).then((res) => (res.data as any[]).map(mapObservationFromDatesStrings));
};

const fetchUserObservationsByPeriodFunc = httpsCallable(functions, 'getUserObservationsForPeriod');
export const fetchUserObservationsByPeriod = ({
    teamId,
    fromDate,
    toDate,
    organizationId,
    userId,
}: {
    teamId: string;
    organizationId: string;
    userId: string;
    fromDate: Date;
    toDate: Date;
}) => {
    return fetchUserObservationsByPeriodFunc({
        teamId,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        organizationId,
        userId,
    }).then((res) => (res.data as any[]).map(mapObservationFromDatesStrings));
};
