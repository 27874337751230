import { takeLatest, all, call, put } from 'redux-saga/effects';
import cyclesActionTypes from './cycles.actionTypes';
import * as cyclesActions from './cycles.actions';
import Cycle from './Cycle';
import {
    createCycleRequest,
    fetchCycleRequest,
    fetchCyclesRequest,
    updateCycleRequest,
} from 'gateways/cyclesGateway';

export function* fetchCyclesByDateRangeSaga({
    teamId,
    organizationId,
    datesRange,
    onSuccess,
    onFail,
}: ReturnType<typeof cyclesActions.fetchCyclesByDateRange>) {
    try {
        const cycles: Cycle[] = yield call(fetchCyclesRequest, {
            teamId,
            organizationId,
            datesRange,
        });

        if (cycles.length > 0) {
            yield put(cyclesActions.cyclesListReceived({ cycles }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchCycleByIdSaga({
    teamId,
    organizationId,
    cycleId,
    onSuccess,
    onFail,
}: ReturnType<typeof cyclesActions.fetchCycleById>) {
    try {
        const cycle: Cycle = yield call(fetchCycleRequest, {
            teamId,
            organizationId,
            cycleId,
        });

        if (cycle) {
            yield put(cyclesActions.cycleDataReceived({ cycle }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* createCycleSaga({
    cycleData,
    teamId,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof cyclesActions.createCycle>) {
    try {
        const cycleId: string = yield call(createCycleRequest, {
            teamId,
            organizationId,
            cycleData,
        });
        yield put(cyclesActions.fetchCycleById({ cycleId, teamId, organizationId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* updateCycleSaga({
    cycleData,
    teamId,
    cycleId,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof cyclesActions.updateCycle>) {
    try {
        yield call(updateCycleRequest, {
            cycleData,
            teamId,
            cycleId,
            organizationId,
        });
        yield put(cyclesActions.fetchCycleById({ cycleId, teamId, organizationId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* cyclesSaga() {
    yield all([
        takeLatest(cyclesActionTypes.UPDATE_CYCLE, updateCycleSaga),
        takeLatest(cyclesActionTypes.CREATE_CYCLE, createCycleSaga),
        takeLatest(cyclesActionTypes.FETCH_CYCLES_BY_DATE_RANGE, fetchCyclesByDateRangeSaga),
        takeLatest(cyclesActionTypes.FETCH_CYCLE_BY_ID, fetchCycleByIdSaga),
    ]);
}

export default cyclesSaga;
