import Observation, { ObservationData, ObservationType } from 'entities/observations/Observation';
import actionTypes from './observations.actionTypes';

export const observationsDataRequest = ({
    observationId,
    playerId,
}: {
    observationId: string;
    playerId: string;
}) => ({
    type: actionTypes.OBSERVATION_DATA_REQUEST,
    observationId,
    playerId,
});

export const userObservationsForPeriodListRequest = ({
    userId,
    organizationId,
    teamId,
    fromDate,
    toDate,
    onSuccess,
    onFail,
}: {
    userId: string;
    organizationId: string;
    teamId: string;
    fromDate: Date;
    toDate: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.USER_OBSERVATIONS_FOR_PERIOD_LIST_REQUEST,
    userId,
    organizationId,
    teamId,
    fromDate,
    toDate,
    onSuccess,
    onFail,
});

export const playerEventObservationsRequest = ({
    playerId,
    eventId,
}: {
    playerId: string;
    eventId: string;
}) => ({
    type: actionTypes.PLAYER_EVENT_OBSERVATIONS_LIST_REQUEST,
    playerId,
    eventId,
});

export const playerGoalObservationsRequest = ({
    playerId,
    goalId,
}: {
    playerId: string;
    goalId: string;
}) => ({
    type: actionTypes.PLAYER_GOAL_OBSERVATIONS_LIST_REQUEST,
    playerId,
    goalId,
});

export const playerInjuryObservationsRequest = ({
    playerId,
    injuryId,
}: {
    playerId: string;
    injuryId: string;
}) => ({
    type: actionTypes.PLAYER_INJURY_OBSERVATIONS_LIST_REQUEST,
    playerId,
    injuryId,
});

export const observationsListRequest = ({
    playerId,
    readOnly = false,
    observationType,
}: {
    playerId: string;
    readOnly?: boolean;
    observationType?: ObservationType;
}) => ({
    type: actionTypes.OBSERVATIONS_LIST_REQUEST,
    playerId,
    readOnly,
    observationType,
});

export const periodObservationsListRequest = ({
    playerId,
    startDate,
    endDate,
}: {
    playerId: string;
    startDate?: Date;
    endDate?: Date;
}) => ({
    type: actionTypes.PERIOD_PLAYER_OBSERVATIONS_LIST_REQUEST,
    playerId,
    startDate,
    endDate,
});

export const periodTeamObservationsListRequest = ({
    organizationId,
    teamId,
    from,
    to,
    onSuccess,
    onFail,
}: {
    organizationId: string;
    teamId: string;
    from: Date;
    to: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.PERIOD_TEAM_OBSERVATIONS_LIST_REQUEST,
    organizationId,
    teamId,
    from,
    to,
    onSuccess,
    onFail,
});

export const eventTeamObservationsListRequest = ({
    organizationId,
    teamId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    eventId: string;
}) => ({
    type: actionTypes.EVENT_TEAM_OBSERVATIONS_LIST_REQUEST,
    organizationId,
    teamId,
    eventId,
});

export const observationsListRecieved = ({
    observationsList,
}: {
    observationsList: Observation[];
}) => ({
    type: actionTypes.OBSERVATIONS_LIST_RECIEVED,
    observationsList,
});

export const observationDataRecieved = ({ observationData }: { observationData: Observation }) => ({
    type: actionTypes.OBSERVATION_DATA_RECIEVED,
    observationData,
});

export const removeObservationFromStore = ({ observationId }: { observationId: string }) => ({
    type: actionTypes.REMOVE_OBSERVATION_FROM_STORE,
    observationId,
});

export const deleteObservation = ({
    observationId,
    playerId,
    onSuccess,
}: {
    observationId: string;
    playerId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.DELETE_OBSERVATION,
    observationId,
    playerId,
    onSuccess,
});

export const updateObservation = ({
    observationData,
    playerId,
    observationId,
    onSuccess,
}: {
    observationData: ObservationData;
    playerId: string;
    observationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_OBSERVATION,
    observationData,
    playerId,
    observationId,
    onSuccess,
});

export const createObservation = ({
    observationData,
    playerId,
    onSuccess,
}: {
    observationData: ObservationData;
    playerId: string;
    onSuccess?: (observationId: string) => void;
}) => ({
    type: actionTypes.CREATE_OBSERVATION,
    observationData,
    playerId,
    onSuccess,
});
