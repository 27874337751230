import qs from 'qs';
import { EventTypes } from 'entities/events/Event';
import { PlanningPeriods, ScheduleViewTypes } from 'features/calendar/Calendar';

export const getParam = ({ location, param }: { location: any; param: string }) => {
    const query = location.search || '';
    const queryObj = qs.parse(query, { ignoreQueryPrefix: true });
    return (queryObj[param] as string) || null;
};

export const getMode = ({ location }: { location: any }) => getParam({ location, param: 'mode' });

export const getActionCode = ({ location }: { location: any }) =>
    getParam({ location, param: 'oobCode' });

/* Scouting */
export const getLonglistUrl = (organizationId: string, listId: string) => {
    return `/organizations/${organizationId}/scouting/longlists/${listId}`;
};

export const getOrgLonglistsUrl = (organizationId: string) => {
    return `/organizations/${organizationId}/scouting/longlists`;
};

export const getPlayerScoutingUrl = (organizationId: string, playerId: string) => {
    if (!organizationId || !playerId) return '';

    return `/organizations/${organizationId}/scouting/players/${playerId}`;
};

export const getScoutingMatchReportUrl = (organizationId: string, reportId: string) => {
    if (!organizationId || !reportId) return '';

    return `/organizations/${organizationId}/scouting/matches/${reportId}`;
};

/* Team */
export const getTeamUrl = (organizationId: string, teamId: string) => {
    if (!organizationId || !teamId) return '';

    return `/organizations/${organizationId}/teams/${teamId}`;
};

export const getTeamWeekCalendarUrl = (organizationId: string, teamId: string) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    return `${teamUrl}/calendar/week`;
};

export const getTeamWeekSimpleCalendarUrl = (organizationId: string, teamId: string) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    return `${teamUrl}/simple-calendar/week`;
};

export const getMedicalTestUrl = (
    organizationId: string,
    teamId: string,
    medicalTestId: string,
) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    if (!teamUrl || !medicalTestId) return '';

    return `${teamUrl}/medical-tests/${medicalTestId}`;
};

export const getTeamEvaluationsPage = ({
    organizationId,
    teamId,
    period,
    eventType,
}: {
    organizationId: string;
    teamId: string;
    period: PlanningPeriods;
    eventType: EventTypes.game | EventTypes.session;
}) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    if (!teamUrl || !eventType || !period) return '';

    return `${teamUrl}/evaluations/${eventType}?period=${period}`;
};

export const getTeamGoalsPage = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    if (!teamUrl) return '';

    return `${teamUrl}/goals`;
};

/* Players */
export const getPlayerUrl = (organizationId: string, teamId: string, playerId?: string | null) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    if (!teamUrl || !playerId) return '';

    return `${teamUrl}/players/${playerId}`;
};

export const getPlayerGoalsUrl = (organizationId: string, teamId: string, playerId: string) => {
    const playerPageUrl = getPlayerUrl(organizationId, teamId, playerId);

    if (!playerPageUrl) return '';

    return `${playerPageUrl}/goals`;
};

export const getPlayerMedicalRecordsUrl = (
    organizationId: string,
    teamId: string,
    playerId: string,
) => {
    const playerPageUrl = getPlayerUrl(organizationId, teamId, playerId);

    if (!playerPageUrl) return '';

    return `${playerPageUrl}/medical-records`;
};

export const getPlayerFitnessDataUrl = (
    organizationId: string,
    teamId: string,
    playerId?: string | null,
) => {
    const playerPageUrl = getPlayerUrl(organizationId, teamId, playerId);

    if (!playerPageUrl) return '';

    return `${playerPageUrl}/fitness-data`;
};

export const getPlayerSkillsUrl = (organizationId: string, teamId: string, playerId: string) => {
    const playerPageUrl = getPlayerUrl(organizationId, teamId, playerId);

    if (!playerPageUrl) return '';

    return `${playerPageUrl}/skills`;
};

export const getPlayerEvaluationsPage = ({
    organizationId,
    teamId,
    playerId,
    period,
    eventType,
}: {
    organizationId: string;
    teamId: string;
    playerId: string;
    period: PlanningPeriods;
    eventType: EventTypes.game | EventTypes.session;
}) => {
    const playerPageUrl = getPlayerUrl(organizationId, teamId, playerId);

    if (!playerPageUrl) return '';

    return `${playerPageUrl}/evaluations/${eventType}?period=${period}`;
};

export const getSessionUrl = (organizationId: string, teamId: string, sessionId: string) => {
    const teamUrl = getTeamUrl(organizationId, teamId);
    return `${teamUrl}/planning/sessions/${sessionId}`;
};

export const getGameUrl = (organizationId: string, teamId: string, gameId: string) => {
    const teamUrl = getTeamUrl(organizationId, teamId);
    return `${teamUrl}/planning/games/${gameId}`;
};

export const getCalendarUrl = (
    organizationId: string,
    teamId: string,
    viewType: ScheduleViewTypes,
    period: PlanningPeriods,
) => {
    const teamUrl = getTeamUrl(organizationId, teamId);

    return `${teamUrl}/${viewType}/${period}`;
};
