import _keyBy from 'lodash/keyBy';
import _sumBy from 'lodash/sumBy';
import User from 'entities/users/User';
import { EventTypes } from 'entities/events/Event';
import { convertUserToAuthorInfo } from 'entities/users/users.utils';
import {
    AggType,
    ColumnConfig,
    DataProvider,
    FitnessRecordValues,
    NumericFitnessDataKeys,
    PlayerFitnessRecord,
    TeamEventFitnessRecordData,
} from './FitnessData';

export const createEventFitnessDataRecord = ({
    playersFitnessRecords,
    user,
    teamId,
    organizationId,
    eventId,
    date,
    eventType,
}: {
    playersFitnessRecords: PlayerFitnessRecord[];
    user: User;
    teamId: string;
    organizationId: string;
    eventId: string;
    eventType: EventTypes.game | EventTypes.session;
    date: Date;
}) => {
    const playersWithAddedEventFields = playersFitnessRecords.map((playerRecord) => ({
        ...playerRecord,
        date,
        eventId,
        eventType,
    }));

    const playersWithChangedKeys = Object.fromEntries(
        playersWithAddedEventFields.map((record) => [record.playerId || record.athlete, record]),
    );

    const totalDuration = playersFitnessRecords[0]?.totalTime || 0;
    const playersIds = playersWithAddedEventFields
        .map((record) => record.playerId)
        .filter(Boolean) as string[];

    const eventFitnessDataRecord: TeamEventFitnessRecordData = {
        dataProvider: DataProvider.gpExe,
        eventId,
        eventType,
        teamId,
        organizationId,
        date,
        totalDuration,
        players: playersWithChangedKeys,
        playersIds,
        author: convertUserToAuthorInfo(user)!,
    };
    return eventFitnessDataRecord;
};

export const mapFitnessDataFromDatesStrings = (fitnessData: TeamEventFitnessRecordData) => {
    const date = new Date(fitnessData.date);
    const playersArr = Object.values(fitnessData.players).map((playerData) => ({
        ...playerData,
        date: new Date(playerData.date),
    }));
    const players = _keyBy(playersArr, (player) => player.playerId || player.athlete);

    return { ...fitnessData, date, players };
};

export const getNonEmptyParameters = (playersRecordsList: FitnessRecordValues[]) => {
    return Object.values(NumericFitnessDataKeys).filter((supportedKey) => {
        return playersRecordsList.some((record) => {
            const isNotEmpty = record[supportedKey] !== undefined && record[supportedKey] !== null;
            return isNotEmpty;
        });
    });
};

export const getAvgForParam = (
    fitnessRecordsList: FitnessRecordValues[],
    key: NumericFitnessDataKeys,
) => {
    const recordsWithValues = fitnessRecordsList.filter((record) => record[key]);
    const sum = _sumBy(recordsWithValues, key);
    const result = Math.round((sum / recordsWithValues.length) * 100) / 100;

    return isNaN(result) ? null : result;
};

export const getSumForParam = (
    fitnessRecordsList: FitnessRecordValues[],
    key: NumericFitnessDataKeys,
) => {
    const recordsWithValues = fitnessRecordsList.filter((record) => record[key]);
    const result = _sumBy(recordsWithValues, key);

    return isNaN(result) ? null : result;
};

export const getSummariesMap = (eventRecords: FitnessRecordValues[], columns?: ColumnConfig[]) => {
    const parametersList = columns
        ? columns
        : getNonEmptyParameters(eventRecords).map((param) => ({ param, summary: ['avg'] }));
    const avgValuesMap = parametersList.map(({ param, summary }) => {
        const avg = summary.includes('avg') ? getAvgForParam(eventRecords, param) : null;
        const sum = summary.includes('sum') ? getSumForParam(eventRecords, param) : null;

        return [param, { avg, sum }];
    });

    return Object.fromEntries(avgValuesMap);
};

export const aggregateFitnessRecords = (
    eventRecords: FitnessRecordValues[],
    config: { [key in NumericFitnessDataKeys]?: AggType },
) => {
    const avgValuesMap = Object.entries(config).map(([param, aggType]) => {
        if (aggType == AggType.avg) {
            const avg = getAvgForParam(eventRecords, param as NumericFitnessDataKeys);
            return [param, avg];
        }

        if (aggType == AggType.sum) {
            const sum = getSumForParam(eventRecords, param as NumericFitnessDataKeys);
            return [param, sum];
        }

        return [param, null];
    });

    return Object.fromEntries(avgValuesMap);
};

export const getEventAverageValues = (eventRecords: FitnessRecordValues[]) => {
    const parametersList = getNonEmptyParameters(eventRecords);
    const avgValuesMap = parametersList.map((columnKey) => {
        const avg = getAvgForParam(eventRecords, columnKey);

        return [columnKey, avg];
    });

    return Object.fromEntries(avgValuesMap);
};
