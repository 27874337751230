import React, { useEffect } from 'react';
import ReduxToastr from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import User from 'entities/users/User';
import Layout from 'common/layout/Layout';
import { auth, db } from 'common/firebase';
import Snackbar from 'common/snackbar/Snackbar';
import { doc, onSnapshot } from 'firebase/firestore';
import { handleDocumentSnapshot } from 'gateways/utils';
import { setUserData } from 'entities/users/users.actions';
// import { getUserLocale } from 'entities/users/users.utils';
import { useLocalConfig } from 'common/config/config.utils';
// import { setDefaultOptions } from 'date-fns/setDefaultOptions';
import { requestAppConfig } from 'common/config/config.actions';
import { appConfigSelector } from 'common/config/config.selectors';
import { handleAuthStateChanged } from 'features/auth/auth.actions';
import { useUserLang } from 'features/profile/components/language.hooks';
import { requestDefaultPermissions, requestUserAccessRecords } from 'entities/access/access.actions';

const Root: React.FC = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const { currentLang } = useUserLang();
    const appConfig = useSelector(appConfigSelector);
    useLocalConfig();

    useEffect(() => {
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }, [currentLang]);

    useEffect(() => {
        let unsubscribe: any;
        dispatch(requestAppConfig());
        dispatch(requestDefaultPermissions());

        auth.onAuthStateChanged((user) => {
            dispatch(handleAuthStateChanged({ user, t }));

            if (user) {
                dispatch(requestUserAccessRecords());
                const userRef = doc(db, `users/${user.uid}`);
                unsubscribe = onSnapshot(userRef, (doc) => {
                    // @ts-ignore
                    const updatedUserData: User = handleDocumentSnapshot(doc);

                    if (!updatedUserData) return;

                    // setDefaultOptions({ locale: getUserLocale(updatedUserData) });
                    dispatch(setUserData(updatedUserData));
                });
            } else if (unsubscribe) {
                unsubscribe();
            }
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    return (
        <>
            <Layout t={t} appConfig={appConfig} />
            <Snackbar />
            <ReduxToastr
                timeOut={6000}
                newestOnTop={false}
                position="bottom-left"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
            {/* <InstallPwaButton /> */}
        </>
    );
};

export default Root;
