import { addDays, addMonths, addWeeks, subDays, subMonths, subWeeks } from 'date-fns';
import calendarActionTypes from './calendar.actionTypes';
import { PlanningPeriods, ScheduleViewTypes } from './Calendar';

export type SearchDrillConfig = {
    text: string;
    withRecordings: boolean;
};

const initialState = {
    isSessionFormOpen: false,
    sessionToEditId: null,
    gameToCreateDate: new Date(),
    selectedDate: new Date(),
    searchDrillConfig: {
        text: '',
        withRecordings: false,
    },
    lastCalendarViewType: null,
    lastCalendarPeriod: null,
};

type InitialState = {
    isSessionFormOpen: boolean;
    sessionToEditId: null | string;
    gameToCreateDate: Date;
    selectedDate: Date;
    searchDrillConfig: SearchDrillConfig;
    lastCalendarViewType: ScheduleViewTypes | null;
    lastCalendarPeriod: PlanningPeriods | null;
};

// eslint-disable-next-line default-param-last
const calendar = (state: InitialState = initialState, action: any) => {
    switch (action.type) {
        case calendarActionTypes.SET_SEARCH_DRILL_CONFIG:
            return {
                ...state,
                searchDrillConfig: action.searchDrillConfig,
            };
        case calendarActionTypes.GO_TODAY:
            return {
                ...state,
                selectedDate: new Date(),
            };
        case calendarActionTypes.GO_NEXT_DAY:
            return {
                ...state,
                selectedDate: addDays(state.selectedDate, 1),
            };
        case calendarActionTypes.GO_PREVIOUS_DAY:
            return {
                ...state,
                selectedDate: subDays(state.selectedDate, 1),
            };
        case calendarActionTypes.GO_NEXT_MONTH:
            return {
                ...state,
                selectedDate: addMonths(state.selectedDate, 1),
            };
        case calendarActionTypes.GO_PREVIOUS_MONTH:
            return {
                ...state,
                selectedDate: subMonths(state.selectedDate, 1),
            };
        case calendarActionTypes.GO_NEXT_WEEK:
            return {
                ...state,
                selectedDate: addWeeks(state.selectedDate, 1),
            };
        case calendarActionTypes.SET_DATE:
            return {
                ...state,
                selectedDate: action.date,
            };
        case calendarActionTypes.GO_PREVIOUS_WEEK:
            return {
                ...state,
                selectedDate: subWeeks(state.selectedDate, 1),
            };
        case calendarActionTypes.SET_LAST_CALENDAR_VIEW_PARAMS:
            return {
                ...state,
                lastCalendarViewType: action.viewType,
                lastCalendarPeriod: action.period,
            };
        default:
            return state;
    }
};

export default calendar;
