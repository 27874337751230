import { DrillData } from 'entities/drills/Drill';
import { EventPlayerInfo, EventTypes } from './Event';
import { ExternalLink } from 'common/external-links/ExternalLink';

export type Position = {
    x: number;
    y: number;
};

export type FormationGroup = {
    name: string;
    positions: { [key: string]: Position };
};

export type PlayerInGroup = {
    playerId: string;
    position: Position | null;
};

export type Group = {
    color: string;
    name: string;
    // it was a number before, but become an string id. Migrate key name to id later if needed
    setNumber: string;
    players: PlayerInGroup[];
};

// export type Variation = {
//     name: string;
//     pitchType: string;
//     groups: FormationGroup[];
// };

// export type Variations = {
//     [key: string]: Variation;
// };

export enum SessionPhase {
    warmup = 'warmup',
    main = 'main',
    cooldown = 'cooldown',
}

export enum PlayersType {
    goalkeepers = 'goalkeepers',
    fieldPlayers = 'fieldPlayers',
}

export type SessionDrill = DrillData & {
    id: string;
    drillId: string;
    groups: Group[];
    phase: SessionPhase | null;
};

export enum AttendanceStatus {
    present = 'present',
    ill = 'ill',
    skip = 'skip',
    reason = 'reason',
    injury = 'injury',
    promotion = 'promotion',
}

export type AttendanceRecord = {
    status: AttendanceStatus;
    time: number;
    player: EventPlayerInfo;
    observations: string[];
};

export type Attendance = Record<string, AttendanceRecord>;

export enum SessionTypes {
    general = 'general',
    individual = 'individual',
    gym = 'gym',
}

export type SessionsSettings = {
    showSessionDrills: 'nope' | 'afterSession' | 'ifAvailable';
    showSessionFiles: boolean;
    showPlayerEvaluations: boolean;
    showDrillDetail: boolean;
    showDrillRecording: boolean;
    showDrillGroups: boolean;
    showDrillVideo: boolean;
    showSessionLinks: boolean;
};

export type SessionDrillsGroup = { groupId: string; drillsIds: string[] };

export type ParallelDrills = Record<string, SessionDrillsGroup>;

export type SessionData = {
    start: Date;
    end: Date;
    eventType: EventTypes;
    attendance: Attendance;
    createDate: Date | null;
    details: {
        sessionType: SessionTypes;
        title: string;
        playerNotes: string;
        description: string;
        drills: SessionDrill[];
        gkDrills: SessionDrill[];
        parallelDrills?: ParallelDrills;
        equipment: string;
        location: string;
        objectives: string;
        files: string[];
        links: ExternalLink[];
    };
    settings?: SessionsSettings;
    lastUpdateAuthorUid: string | null;
};

type Session = SessionData & {
    teamId: string;
    organizationId: string;
    id: string;
};

export default Session;
