import { RootState } from 'store';
import { isBefore } from 'date-fns';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import Team, { TeamStatus } from './Team';

export const teamsHashSelector = (state: RootState) => state.entities.teams.teamsHash;

export const teamsListSelector = createSelector(
    teamsHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (teamsHash, organizationId) => {
        const teamsList: Team[] = Object.values(teamsHash);
        return teamsList.filter((team: Team) => team.organizationId === organizationId);
    },
);

export const activeTeamsListSelector = createSelector(teamsListSelector, (teamsList) =>
    teamsList.filter((team: Team) => team.status === TeamStatus.active),
);

export const teamDataSelector = createSelector(
    teamsHashSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (teamsHash, teamId) => teamsHash[teamId],
);

export const teamsUpdatesHashSelector = (state: RootState) => state.entities.teams.teamsUpdatesHash;

export const teamsUpdatesListSelector = createSelector(
    teamsUpdatesHashSelector,
    (teamsUpdatesHash) => {
        return Object.values(teamsUpdatesHash);
    },
);

export const teamUpdatesListSelector = createSelector(
    teamsUpdatesListSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (teamsUpdatesList, teamId) => {
        const result = teamsUpdatesList.filter(
            (teamUpdateRecord) => teamUpdateRecord.teamId === teamId,
        );
        result.sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));

        return result;
    },
);

export const playersLabelsHashSelector = (state: RootState) => {
    return state.entities.teams.teamsPlayersLabelsHash;
};

export const playersLabelsSelector = createSelector(
    playersLabelsHashSelector,
    (_: RootState, { teamId }: { teamId: string }) => teamId,
    (playersLabelsHashSelector, teamId) => playersLabelsHashSelector[teamId],
);

export const gameTypesMetadataHashSelector = (state: RootState) => {
    return state.entities.teams.teamsGameTypesHash;
};

export const gameTypesMetadataSelector = createSelector(
    gameTypesMetadataHashSelector,
    (_: RootState, { teamId }: { teamId: string }) => teamId,
    (gameTypesMetadataHashSelector, teamId) => gameTypesMetadataHashSelector[teamId],
);
