import * as teamsGateway from 'gateways/teamsGateway';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { TeamUpdate } from './TeamUpdates';
import * as teamsActions from './teams.actions';
import Team, { GameTypesMetadata } from './Team';
import teamsActionTypes from './teams.actionTypes';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';
import {
    deleteTeamRequest,
    removeTeam,
    requestTeamUpdatesList,
    teamDataRecieved,
    teamDataRequest,
    teamDataUpdate,
    teamsListRecieved,
    teamsListRequest,
    teamUpdatesListReceived,
} from './teams.actions';

export function* fetchTeamDataSaga({ teamId, organizationId }: ReturnType<typeof teamDataRequest>) {
    try {
        const teamData: Team = yield call([teamsGateway, teamsGateway.fetchTeam], {
            teamId,
            organizationId,
        });
        yield put(teamDataRecieved({ teamData }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchTeamsListSaga({ organizationId }: ReturnType<typeof teamsListRequest>) {
    try {
        const teamsList: Team[] = yield call([teamsGateway, teamsGateway.fetchTeamsList], {
            organizationId,
        });
        yield put(teamsListRecieved({ teamsList }));
    } catch (e) {
        console.error(e);
    }
}

export function* deleteTeamSaga({ teamId, organizationId }: ReturnType<typeof deleteTeamRequest>) {
    try {
        yield call([teamsGateway, teamsGateway.deleteTeam], { teamId, organizationId });
        yield put(removeTeam({ teamId }));
    } catch (e) {
        console.error(e);
    }
}

export function* updateTeamDataSaga({
    teamId,
    organizationId,
    teamData,
}: ReturnType<typeof teamDataUpdate>) {
    try {
        yield call([teamsGateway, teamsGateway.updateTeamData], {
            teamData,
            teamId,
            organizationId,
        });
        yield call(fetchTeamDataSaga, { type: '', teamId, organizationId });
    } catch (e) {
        console.error(e);
    }
}

export function* fetchTeamUpdatesListSaga({
    organizationId,
    teamId,
}: ReturnType<typeof requestTeamUpdatesList>) {
    try {
        const teamUpdatesList: TeamUpdate[] = yield call(
            [teamsGateway, teamsGateway.fetchTeamUpdatesList],
            { organizationId, teamId },
        );
        yield put(teamUpdatesListReceived({ teamUpdatesList }));
    } catch (e) {
        console.error(e);
    }
}
export function* updatePlayersLabelsMetadata({
    organizationId,
    teamId,
    playersLabelsMetadata,
}: ReturnType<typeof teamsActions.updatePlayersLabels>) {
    try {
        yield call(teamsGateway.updatePlayersLabelsMetadata, {
            organizationId,
            teamId,
            playersLabelsMetadata,
        });
        yield put(teamsActions.requestPlayersLabels({ teamId, organizationId }));
    } catch (e: any) {
        console.error('Update player labels error', e);
        throw new Error(e);
    }
}

export function* fetchPlayersLabelsSaga({
    organizationId,
    teamId,
}: ReturnType<typeof teamsActions.updatePlayersLabels>) {
    try {
        const playersLabels: PlayersLabelsMetadata = yield call(teamsGateway.fetchPlayersLabels, {
            organizationId,
            teamId,
        });
        if (playersLabels) {
            yield put(teamsActions.playersLabelsDataReceived({ playersLabels, teamId }));
        }
    } catch (e: any) {
        console.error('Fetch player labels error', e);
        throw new Error(e);
    }
}

export function* updateGameTypesMetadataSaga({
    organizationId,
    teamId,
    gameTypesMetadata,
}: ReturnType<typeof teamsActions.updateGameTypes>) {
    try {
        yield call(teamsGateway.updateGameTypesMetadata, {
            organizationId,
            teamId,
            gameTypesMetadata,
        });
        yield put(teamsActions.requestGameTypes({ teamId, organizationId }));
    } catch (e: any) {
        console.error('Game types update error', e);
        throw new Error(e);
    }
}

export function* fetchGameTypesMetadataSaga({
    organizationId,
    teamId,
}: ReturnType<typeof teamsActions.requestGameTypes>) {
    try {
        const gameTypesMetadata: GameTypesMetadata = yield call(
            teamsGateway.fetchGameTypesMetadata,
            {
                organizationId,
                teamId,
            },
        );
        if (gameTypesMetadata) {
            yield put(teamsActions.gameTypesDataReceived({ gameTypesMetadata, teamId }));
        }
    } catch (e: any) {
        console.error('Game types fetch error', e);
        throw new Error(e);
    }
}

function* teamsSagas() {
    yield all([
        takeLatest(teamsActionTypes.UPDATE_PLAYERS_LABELS, updatePlayersLabelsMetadata),
        takeLatest(teamsActionTypes.PLAYERS_LABELS_DATA_REQUEST, fetchPlayersLabelsSaga),
        takeLatest(teamsActionTypes.TEAMS_LIST_REQUEST, fetchTeamsListSaga),
        takeLatest(teamsActionTypes.DELETE_TEAM, deleteTeamSaga),
        takeLatest(teamsActionTypes.TEAM_DATA_UPDATE, updateTeamDataSaga),
        takeLatest(teamsActionTypes.TEAM_DATA_REQUEST, fetchTeamDataSaga),
        takeLatest(teamsActionTypes.TEAM_UPDATES_LIST_REQUEST, fetchTeamUpdatesListSaga),
        takeLatest(teamsActionTypes.UPDATE_GAME_TYPES, updateGameTypesMetadataSaga),
        takeLatest(teamsActionTypes.GAME_TYPES_DATA_REQUEST, fetchGameTypesMetadataSaga),
    ]);
}

export default teamsSagas;
